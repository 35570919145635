<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData", "options"],
  methods: {
    addDollarSign() {
      this.addPlugin({
        id: "dollar_sign",
        afterDraw: function(chart) {
          var ctx = chart.ctx;
          ctx.fillStyle = "gray";
          ctx.fillText("($)", chart.width - 20, chart.height - 10);
        }
      });
    },
    drawMaxPoint() {
      this.addPlugin({
        id: "y_max_point",
        beforeDatasetsDraw: function(chart) {
          let ctx = chart.ctx;
          ctx.fillStyle = '#E76F86'
          const lineData = chart.data.datasets[0].data;

          const indexOfyValuesMax = chart.data.datasets[0].indexOfyValuesMax;
          const { x: xMax, y: yMax } = lineData[indexOfyValuesMax];

          const x_position = chart.scales["time-axis"].getPixelForValue(xMax);
          const y_position = chart.scales["y-axis"].getPixelForValue(yMax);

          ctx.beginPath();
          ctx.arc(x_position, y_position, 8, 0, 2 * Math.PI);
          ctx.fill();
        }
      });
    },
    drawChart() {
      this.drawMaxPoint();
      this.addDollarSign();
      this.renderChart(this.chartData, this.options);
    }
  },
  computed: {},
  watch: {
    chartData() {
      this.drawChart();
    }
  },
  mounted() {
    this.addDollarSign();
    this.renderChart(this.chartData, this.options);
    this.drawChart();
  },
  created() {}
};
</script>

<style>
</style>